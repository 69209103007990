.antispam_robot{
  input, label{
    width: 0!important;
    height: 0!important;
    opacity: 0!important;
  }
  width: 0!important;
  height: 0!important;
}

#contact-form{
  input, textarea{
    width: 50%!important;
    padding: 10px!important;
    margin: 10px!important;
  }
  button{
    margin: 10px!important;
    border: none;
  }
  .is-invalid{
    border: 1px solid #b41919!important;
    color: #b41919!important;
  }
}
span#quote{
  font-size: 28px!important;
}